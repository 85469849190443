$(function () {

    if ($('#calendar-course').html()) {


        function reInitAjaxCartHandling() {

            $('.add-to-cart-link').each(function () {

                thisButton = $(this);
                if (!thisButton.attr('can-submit')) {
                    thisButton.attr('can-submit', 'true');

                    $(this).click(function () {
                        thisButton = $(this);
                        courseId = thisButton.attr('data-course-id');
                        csrfToken = thisButton.attr('data-csrf-token');
                        var tuition = thisButton.attr('tuition') === 'true';

                        if (searchCartForCourseId(cartContents, courseId).length === 0) {

                            // CSRF protection
                            $.ajaxSetup(
                              {
                                  headers:
                                    {
                                        'X-CSRF-Token': csrfToken
                                    }
                              });

                            $.ajax(
                              {
                                  type: "GET",
                                  url: '/cart/add',
                                  dataType: 'json',
                                  data: {
                                      id: courseId,
                                      tuition: tuition,
                                  },
                                  success: function (data) {

                                      thisButton.html('View Cart');
                                      thisButton.addClass('registered');

                                      cartContents = data;
                                      refreshNavBarCart(cartContents);
                                  }

                              });

                        } else {
                            window.location.href = "/cart/";
                        }

                        return false;

                    });
                }

            });

            $('.course-calendar--paying-options input[type="radio"]').on('change', function () {
                if($(this).attr('value') === 'full_cost') {
                    if ($(this).parent().parent().find('.calendar-button-container .add-to-cart-link').length)
                        $(this).parent().parent().find('.calendar-button-container .add-to-cart-link').attr('tuition', 'false');
                    else
                        $(this).parent().parent().parent().parent().parent().parent().find('.add-to-cart-link').attr('tuition', 'false');
                } else {
                    if ($(this).parent().parent().find('.calendar-button-container .add-to-cart-link').length)
                        $(this).parent().parent().find('.calendar-button-container .add-to-cart-link').attr('tuition', 'true');
                    else
                        $(this).parent().parent().parent().parent().parent().find('.add-to-cart-link').attr('tuition', 'true');
                }
            });
        }

        $('#calendar-course').fullCalendar({

            header: {
                left: 'title',
                center: '',
                right: ''
            },

            columnFormat: 'dddd',
            editable: false,
            height: 'auto',
            eventLimit: false, // allow "more" link when too many events

            events: eventLink,

            eventClick: function (event, jsEvent, view) {

                $('.popover').popover('hide');

                $this = $(this);

                $this.popover({
                    html: true,
                    title: event.title,
                    content: event.description + returnRegistrationButton(
                      event.id,
                      event.skip_registration_button,
                      event.has_tuition,
                      event.tuition,
                      event.cost
                    ),
                    placement: 'top',
                    container: 'body',
                    trigger: 'focus'
                }).popover('show');

                reInitAjaxCartHandling();
                return false;
            }

        });

        $("#detail-page-calendar").addClass('hidden');

        $("#list-view-btn").click(function () {

            $("#list-view").show();

            $("#detail-page-calendar").addClass('hidden');

        });

        $("#calendar-view-btn").click(function () {

            $("#detail-page-calendar").removeClass('hidden');

            $("#list-view").hide();

            $('#calendar-course').fullCalendar('refetchEvents');

        });

        $('#previous-month-btn').click(function () {

            $('#calendar-course').fullCalendar('prev');

        });

        $('#next-month-btn').click(function () {

            $('#calendar-course').fullCalendar('next');

        });

        $('.container-fluid').click(function () {
            $('.popover').popover('hide');
        });

    }


    $("#modal").iziModal();

    $('.decline-refund').each(function () {

        $(this).click(function () {

            window.location.href = "/";


            return false;

        });

    });

    /*

        FullCalendar Init

    */
    function compileDateFromSelectors() {
        var month = $('#month-selector').val();
        var year = $('#year-selector').val();

        return month + '/01/' + year;
    }

    function updateDateSelector() {
        var currentViewDate = $("#calendar").fullCalendar('getDate');

        if (currentViewDate.length != 0) {

            var monthInt = currentViewDate.format('MM');
            var yearInt = currentViewDate.format('YYYY');

            $('#month-selector').val(monthInt);
            $('#year-selector').val(yearInt);

        }
    }

    if ($('#calendar-index')) {

        // Category switch handling:
        var currentSource = '/course-calendar/all';

        var currentlySelected;

        var csrfToken = $('input[name="_token"]').val();

        function returnRegistrationButton(courseId, skipRegistrationButton, hasTuition, tuitionCost, fullCost) {

            var returnString;
            if ( skipRegistrationButton ) {
                returnString = '<span class="calendar-button-unavailable">Registration no longer available</span>';
            } else {

                fullCost = parseFloat(fullCost).toFixed(2);
                if (hasTuition) {
                    tuitionCost = parseFloat(tuitionCost).toFixed(2);

                    returnString = '<div class="course-calendar--paying-options" style="width: 70%;">\
                                        <div class="d-flex flex-column mt-4">\
                                            <div class="input-group mb-3 d-flex align-items-center">\
                                                <input class="mt-0 mr-2" name="cost_type" id="full_cost" type="radio" value="full_cost" checked>\
                                                <label style="width: 100%;" class="d-flex justify-content-between" id="full_cost_label" for="full_cost"><span>Full Tuition:</span><span style="margin-left: auto">$' + fullCost + '</span></label>\
                                            </div>\
                                            <div class="input-group mb-3 d-flex align-items-center">\
                                                <input class="mt-0 mr-2" name="cost_type" id="tuition" type="radio" value="tuition">\
                                                <label style="width: 100%;" class="d-flex justify-content-between" id="tuition_label" for="tuition"><span>Application Fee Only:</span><span style="margin-left: auto">$' + tuitionCost + '</span></label>\
                                            </div>\
                                            <span class="calendar-button-container" style="text-align: center;"><button tuition="false" class="btn btn-warning add-to-cart-link" data-course-id="' + courseId + '">Register</button></span>\
                                        </div>\
                                    </div>';
                } else {
                    returnString = '<div class="course-calendar--paying-options">\
                                      <div class="d-flex flex-column mt-4">\
                                          <div class="input-group mb-3 d-flex align-items-center">\
                                          Cost: $' + fullCost + '\
                                    </div>\
                                        <span class="calendar-button-container"><button class="btn btn-warning add-to-cart-link" data-course-id="' + courseId + '" data-csrf-token="' + csrfToken + '">Register</button></span>\
                                    </div>\
                                </div>';
                }

            }

            return returnString;

        }

        $('.course-category-filter').each(function () {

            $(this).click(function () {

                // Remove last selected source
                $('#calendar').fullCalendar('removeEventSource', currentSource);

                // Add the new source from ID of <li> (equal to 'all' or category ID#)
                currentSource = '/course-calendar/' + $(this).attr('id');

                currentlySelected = $(this).children('a').text();

                $('#calendar').fullCalendar('removeEvents');

                $('#calendar').fullCalendar('addEventSource', currentSource);

                $('#currently-selected-category').text(currentlySelected);

            });

        });

        $('.container').click(function () {
            $('.popover').popover('hide');
        });

        $('#previous-month-btn').click(function () {

            $('#calendar').fullCalendar('prev');

        });

        $('#next-month-btn').click(function () {

            $('#calendar').fullCalendar('next');

        });

        $('#calendar').fullCalendar({

            header: {
                left: 'title',
                center: '',
                right: ''
            },

            columnFormat: 'dddd',

            editable: false,

            height: 'auto',

            eventLimit: true, // allow "more" link when too many events

            eventSources: '/course-calendar/all',

            eventMouseover: function (event, jsEvent, view) {


            },

            eventMouseout: function (event, jsEvent, view) {


            },

            viewRender: function (view, element) {
                var currentViewDate = view.intervalStart;
                var monthInt = currentViewDate.format('MM');
                var yearInt = currentViewDate.format('YYYY');

                $('#month-selector').val(monthInt);
                $('#year-selector').val(yearInt);
            },

            // CALL BUTTON GENERATION FUNCTION
            eventAfterAllRender: reInitAjaxCartHandling(),

            eventClick: function (event, jsEvent, view) {

                $('.popover').popover('hide');

                $this = $(this);

                $this.popover({
                    html: true,
                    title: event.title,
                    content: event.description + returnRegistrationButton(
                      event.id,
                      event.skip_registration_button,
                      event.has_tuition,
                      event.tuition,
                      event.cost
                    ),
                    placement: 'top',
                    container: 'body',
                    trigger: 'focus'
                }).popover('show');

                reInitAjaxCartHandling();
                return false;
            }
        });

        $('.date-selector-group').appendTo('.fc-left');

        var select = $("#year-selector"),

            year = new Date().getFullYear() - 6;

        for (var i = 0; i < 16; i++) {

            select.append($("<option value='" + (i + year) + "' " + (i === 0 ? "selected" : "") + ">" + (i + year) + "</option>"))

        }

        updateDateSelector();

        $('.date-selector-group select').change(function () {

            var goToDate = compileDateFromSelectors();
            console.log(goToDate);

            $('#calendar').fullCalendar('gotoDate', goToDate);
        });

    }

    /*
    * Pagination
    * */

    if ($('.btn-paginate')) {
        $('.btn-paginate').each(function () {
            $(this).click(function () {
                thisButton = $(this);
                var page = thisButton.attr('page');

                if (page) {
                    var queryParams = window.location.search;
                    if (queryParams) {
                        queryParams = queryParams.replace('?', '&');
                        queryParams = queryParams.split('&');
                        var queries = '';
                        for (var query of queryParams) {
                            if (query && !query.includes('page')) {
                                queries += "&" + query;
                            }
                        }
                        queryParams = queries;
                    }

                    queryParams = '?page=' + page + queryParams;

                    window.location.href = window.location.pathname + queryParams;

                }
            })
        });
    }


    /*

        Add to cart handling setup

    */
    var cartContents;
    var thisButton;
    var courseId;
    var variationId;
    var csrfToken;

    function getCartContents() {

        $.ajax({
            url: "/cart/contents-json",
            async: false,
            dataType: 'json',
            success: function (data) {
                cartContents = data;

                $('.add-to-cart-link').each(function () {
                    thisButton = $(this);
                    courseId = thisButton.attr('data-course-id');
                    if (searchCartForCourseId(cartContents, courseId).length) {
                        thisButton.html('View Cart');
                        thisButton.addClass('registered');
                    }
                });

            }
        });

        return cartContents;
    }

    function searchCartForCourseId(cartContents, courseId) {

        var searchExpression = '//*[id="' + courseId + '"]';

        var searchResults = JSON.search(cartContents, searchExpression);

        return searchResults;

    }

    function refreshNavBarCart(cartContents) {

        var count = 0;

        $.each(cartContents, function (i, val) {

            if (val.id !== 'taxes') {
                count++;
            }

        });

        $('.cart-icon').each(function () {
            if ($(this).find('sup').length) {
                var sup = $(this).find('sup').first()
                sup.text(count);
            } else {
                $(this).append('<sup>1</sup>');
            }
        })

        if (!isNaN(count)) {
            $('#cart-count').html(count);
        }

        return false;

    }

    cartContents = getCartContents();
    // ------ /END SETUP/ -------
    /*
        Add to cart handling:

        checks if add-to-cart-link's exist, if so:
            sends ajax request adding that item to cart, referenced by html data-course-id attribute. Also: sets csrf token to header.
            returns updated cart contents

    */

    if ($('.add-product-to-cart-link')) {

        $('.add-product-to-cart-link').each(function () {

            $(this).click(function () {

                thisButton = $(this);
                variationId = thisButton.attr('data-variation-id');
                csrfToken = thisButton.attr('data-csrf-token');
                var qty = thisButton.parent().find('input.quantity').first().val();

                if (searchCartForCourseId(cartContents, 'p'.variationId).length === 0) {

                    // CSRF protection
                    $.ajaxSetup(
                      {
                          headers:
                            {
                                'X-CSRF-Token': csrfToken
                            }
                      });

                    $.ajax(
                      {
                          type: "GET",
                          url: '/cart/add-product',
                          dataType: 'json',
                          data: {
                              id: variationId,
                              qty: qty
                          },
                          success: function (data) {

                              $('#box-' + variationId).css('height', '4.3rem').css('margin-top', '1.7rem');

                              cartContents = data;
                              refreshNavBarCart(cartContents);
                              $('.continue-shopping--warning').css('height', '0').css('margin-top', '0');
                              var stockLabel = $('#stock--' + variationId);
                              if(stockLabel.length) {
                                  stockLabel.text(stockLabel.text() - qty);
                              }
                          },
                          error: function (error) {
                              $('.continue-shopping--warning').css('height', '4.3rem').css('margin-top', '1.7rem');
                              setTimeout(function () {
                                  $('.continue-shopping--warning').css('height', '0').css('margin-top', '0');
                              }, 2500);
                          }

                      });

                } else {
                    window.location.href = "/cart/";
                }

            });

        });

    }

    // ------ /END CART HANDLING/ -------

    function reInitAjaxCartHandling() {

        $('.add-to-cart-link').each(function () {

            thisButton = $(this);
            if (!thisButton.attr('can-submit')) {
                thisButton.attr('can-submit', 'true');

                $(this).click(function () {
                    thisButton = $(this);
                    courseId = thisButton.attr('data-course-id');
                    csrfToken = thisButton.attr('data-csrf-token');
                    var tuition = thisButton.attr('tuition') === 'true';

                    if (searchCartForCourseId(cartContents, courseId).length === 0) {

                        // CSRF protection
                        $.ajaxSetup(
                          {
                              headers:
                                {
                                    'X-CSRF-Token': csrfToken
                                }
                          });

                        $.ajax(
                          {
                              type: "GET",
                              url: '/cart/add',
                              dataType: 'json',
                              data: {
                                  id: courseId,
                                  tuition: tuition,
                              },
                              success: function (data) {

                                  thisButton.html('View Cart');
                                  thisButton.addClass('registered');

                                  cartContents = data;
                                  refreshNavBarCart(cartContents);
                              }

                          });

                    } else {
                        window.location.href = "/cart/";
                    }

                    return false;

                });
            }
        });

        $('.course-calendar--paying-options input[type="radio"]').on('change', function () {
            if($(this).attr('value') === 'full_cost') {
                if ($(this).parent().parent().find('.calendar-button-container .add-to-cart-link').length)
                    $(this).parent().parent().find('.calendar-button-container .add-to-cart-link').attr('tuition', 'false');
                else
                    $(this).parent().parent().parent().parent().parent().parent().find('.add-to-cart-link').attr('tuition', 'false');
            } else {
                if ($(this).parent().parent().find('.calendar-button-container .add-to-cart-link').length)
                    $(this).parent().parent().find('.calendar-button-container .add-to-cart-link').attr('tuition', 'true');
                else
                    $(this).parent().parent().parent().parent().parent().find('.add-to-cart-link').attr('tuition', 'true');
            }
        });

    }

});